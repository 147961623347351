import axios from 'axios';

const mainUrl = process.env.REACT_APP_API_GATEWAY_URL
const baseUrl = `${mainUrl}/web-page-conf`


export const getAllResp = async () => {
  const { data: res } = await axios.get(`${baseUrl}/web/social-responsability/getall`,
    { headers: { 'Accept-Encoding': 'gzip, deflate, br' } })
  return res;
};

export const getRespById = async (id) => {
  const { data: res } = await axios.get(`${baseUrl}/web/social-responsability/${id}`);
  return res;
};

export const getMainContent = async () => {
  const { data: res } = await axios.get(`${baseUrl}/web/sustainability/getContentSustainability`);
  return res;
};

export const getPrograms = async () => {
  const { data: res } = await axios.get(`${baseUrl}/web/sustainability/programs`);
  return res;
};

export const getReports = async () => {
  const { data: res } = await axios.get(`${baseUrl}/web/sustainability/reports`);
  return res;
};