import axios from 'axios';

const mainUrl = process.env.REACT_APP_API_GATEWAY_URL 
const baseUrl = `${mainUrl}/web-page-conf`


  const getStates = async () => { 
        const {data: res} = await axios.get(`${baseUrl}/web-contact`);
        return res;
    };

    const getContacts = async () => { 
      const {data: res} = await axios.get(`${baseUrl}/web/contact`);
      return res;
  };

  const getCities = async (idState) => { 
    const {data: res} = await axios.get(`${baseUrl}/common-services/api/catalog/cities/${idState}`);
    return res;
  };

  export const getMsgSostenibilidad = async () => { 
    const {data: res} = await axios.get(`${baseUrl}/web/sustainability/contact/message-default`);
    return res;
  };

  const postContact = async ({ 
    city,
    email,
    message,
    name,
    phone,
    state
  }) => {
    const {data: res} = await axios.post(`${baseUrl}/web/send-message`,{ 
    city,
    email,
    message,
    name,
    phone,
    state});
    return res;
  };

  export default {
    postContact,
    getCities,
    getStates,
    getContacts
  }