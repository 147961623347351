import React, { useState, useEffect } from 'react';
import { AppBar, IconButton } from '@mui/material';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import CssBaseline from '@mui/material/CssBaseline';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar1 from './Toolbar1'
import SectionRequest from './request/SectionRequest'
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Collapse from '@mui/material/Collapse';
import CloseIcon from '@mui/icons-material/Close';
import PersonIcon from '@mui/icons-material/Person';
import { useHistory } from 'react-router-dom'
import { HashLink as Link } from 'react-router-hash-link';
import RequestCommon from '../RequestCommon';
import { NoEncryption } from '@mui/icons-material';


export default function Header(props) {
  const mainUrl = process.env.REACT_APP_API_GATEWAY_URL
  let history = useHistory();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('md'));
  const [data, setData] = useState([])
  const [redes, setRedes] = useState([])
  const [subMenu, setSubMenu] = useState([])
  const { window2 } = props;
  const [mobileOpen, setMobileOpen] = useState(false);
  const [dropdown, setDropdown] = useState(false);
  const [showEstructura, setShowEstructura] = useState(false);
  const [seoDataHome, setSeoDataHome] = useState({});

  
  const onMouseEnter = () => {
    if (window.innerWidth < 960) {
      setDropdown(false);
    } else {
      setDropdown(true);
    }
  };

  const onMouseLeave = () => {
    if (window.innerWidth < 960) {
      setDropdown(false);
    } else {
      setDropdown(false);
    }
  };
  const [dropdown2, setDropdown2] = useState(false);
  const onMouseEnter2 = () => {
    if (window.innerWidth < 960) {
      setDropdown2(false);
    } else {
      setDropdown2(true);
    }
  };

  const onMouseLeave2 = () => {
    if (window.innerWidth < 960) {
      setDropdown2(false);
    } else {
      setDropdown2(false);
    }
  };
  const [dropdown3, setDropdown3] = useState(false);
  const onMouseEnter3 = () => {
    if (window.innerWidth < 960) {
      setDropdown3(false);
    } else {
      setDropdown3(true);
    }
  };

  const onMouseLeave3 = () => {
    if (window.innerWidth < 960) {
      setDropdown3(false);
    } else {
      setDropdown3(false);
    }
  };

  const [dropdown4, setDropdown4] = useState(false);
  const onMouseEnter4 = () => {
    if (window.innerWidth < 960) {
      setDropdown4(false);
    } else {
      setDropdown4(true);
    }
  };

  const onMouseLeave4 = () => {
    if (window.innerWidth < 960) {
      setDropdown4(false);
    } else {
      setDropdown4(false);
    }
  };

  const [dropdown5, setDropdown5] = useState(false);
  const onMouseEnter5 = () => {
    if (window.innerWidth < 960) {
      setDropdown5(false);
    } else {
      setDropdown5(true);
    }
  };

  const onMouseLeave5 = () => {
    if (window.innerWidth < 960) {
      setDropdown5(false);
    } else {
      setDropdown5(false);
    }
  };

  const handleClick = (index2) => {
    setData(data.map((e, index) => {
      if (index !== index2) {
        return {
          ...e,
          open: false
        }
      } else {
        return {
          ...e,
          open: !e.open
        }
      }
    }))
  };



  const container = window2 !== undefined ? () => window().document.body : undefined;

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  useEffect(() => {
    getRedes()
    getInfoData();
    getSeoMetaByCode();
    try {
      SectionRequest.getAllSections().then((res) => {
        setSubMenu(res.map((item) => item.head));
        setData(res.map((e) => {
          return {
            ...e,
            open: false
          }
        }))
        //  console.log('data',data);
        //console.log('submenus',subMenu);
      })
    } catch (error) {
      //console.log(error)
    }
  }, [])

  const getInfoData = () => {
    try {
      SectionRequest.getAllInformationInst().then((res) => {
        // console.log('INFO INSTITU--------',res)
        setShowEstructura((res.description && res.description !== '0' && res.description !== '<p>0</p>') ? true : false);

      })
    } catch (error) {
      console.log(error)
    }
  }

  const getSeoMetaByCode = async () => {
    try {
      RequestCommon.getSEO('I').then((res) => {
        setSeoDataHome(res);
      })
    } catch (error) {
      console.log(error)
    }
  }

  const getRedes = () => {
    try {
      SectionRequest.getAllSocial().then((res) => {
        setRedes(res.map((e) => {
          return {
            ...e,
          }
        }))
      })
    } catch (error) {
      console.log(error)
    }
  }

  function validateURL(link) {
    if (link.indexOf("http://") == 0 || link.indexOf("https://") == 0) {
      return true
    }
    else {
      return false
    }
  }

  const setDataSeoStorage = (data) => {
    window.sessionStorage.setItem("seoRA", JSON.stringify(data));
  }

  const setHomeInStorage = () => {
    setDataSeoStorage(seoDataHome);
  }

  const drawer = (
    <div className="header-mobile">
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12">
            <img className="logoMobile" src={require('./images/REDAMBIENTAL.png').default} alt="Red ambiental" width="130" height="100" />
            <IconButton className="mobile-close" size="large" edge="end" color="primary" onClick={handleDrawerToggle}>
              <CloseIcon />
            </IconButton>
          </div>
          <div className="col-sm-12">
            <List>
              {data.map((item, index) => (
                <>
                  {item.head.length > 0 && item.section_name == "Quienes Somos" ?
                    <>
                      <ListItem button style={{ display: item.enabled == true ? null : 'none' }} onClick={() => { handleClick(index) }}>
                        <ListItemText primary={item.section_name} />
                        {item.open ? <ExpandLess /> : <ExpandMore />}
                      </ListItem>
                      <Collapse in={item.open}>
                        <div className="inner-collapse-menu">
                          {item.head.map((e) => {
                            return (
                              <List>
                                {/* <ListSubheader className="sub-heading" onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}> {e.subsection_head_name}
                        </ListSubheader> */}
                                {e.subsection.map((i) => {
                                  return (
                                    <>
                                      {i.subsection_url == 'presencia_nacional' ?
                                        <Link style={{ textDecoration: 'none', color: 'inherit' }} to="/#map-section">
                                       <ListItem button style={{ width: '100%' }}
                                       // onMouseEnter={onMouseEnter}
                                       // onMouseLeave={onMouseLeave}
                                       onClick={()=>{
                                        setMobileOpen(false);
                                       }}
                                       >
                                         <ListItemText primary={i.subsection_name} />
                                       </ListItem>
                                     </Link>
                                        :
                                        <ListItem button style={{ width: '100%' }}
                                          onMouseEnter={onMouseEnter}
                                          onMouseLeave={onMouseLeave}
                                          onClick={() => {
                                            if (i.subsection_url == 'informes_anuales') {
                                              setMobileOpen(false);
                                              history.push({
                                                pathname: `/${i.subsection_url}`,
                                                state: { detail: i.id_subsection, nombre: i.subsection_name }
                                              })
                                            } else {
                                              if(i.subsection_url == 'presencia_nacional'){
                                                setMobileOpen(false);
                                                history.push(`/#map-section`)
                                              }else{
                                                setMobileOpen(false);
                                                history.push(`/nuestra_empresa/${encodeURIComponent(i.labels_dto.url)}`)
                                              }
                                            }
                                            setDataSeoStorage(i.labels_dto);
                                            //id_subsection: 72
                                            // subsection_name: "Misión"
                                            // subsection_url: "misión"
                                            // history.push({
                                            //   pathname:`/${i.subsection_url}`,
                                            //   state: { detail: i.id_subsection,nombre:i.subsection_name }})

                                          }}>
                                          <ListItemText primary={i.subsection_name} />
                                        </ListItem>
                                      }
                                    </>
                                  )
                                })}
                              </List>
                            )
                          })}
                        </div>
                      </Collapse>
                    </>
                    :
                    <ListItem button onClick={() =>  {setDataSeoStorage(item.labels_dto); history.push(`/${item.section_url}`)}} style={{ display: item.enabled == true ? null : "none" }}>
                      {item.section_name == "Noticias" || item.section_name == "Servicios" || item.section_name == "Contáctanos" || item.section_name == "Responsabilidad Social" || item.section_name == "Bolsa de trabajo" ? null
                        :
                        <ListItemText primary={item.section_name} />
                      }

                    </ListItem>
                  }
                  {item.head.length > 0 && item.section_name == "Noticias" ?
                    <List key={item.id_section} className="nav">
                      <ListItem button style={{ display: item.enabled == true ? null : 'none' }} onClick={() => { handleClick(index) }}>
                        <ListItemText primary={item.section_name} />
                        {item.open ? <ExpandLess /> : <ExpandMore />}
                      </ListItem>
                      <Collapse in={item.open}>
                        <div className="inner-collapse-menu">
                          {item.head.map((e) => {
                            return (
                              <List>
                                <ListSubheader className="sub-heading" onMouseEnter={onMouseEnter2} onMouseLeave={onMouseLeave2}> {e.subsection_head_name}
                                </ListSubheader>
                                {e.subsection.map((i) => {
                                  return (
                                    <ListItem button style={{ width: '100%' }}
                                      onMouseEnter={onMouseEnter2}
                                      onMouseLeave={onMouseLeave2}
                                      onClick={() => {
                                        setMobileOpen(false);
                                        history.push({
                                        pathname: `/${i.subsection_url}`,
                                        state: { detail: i.id_subsection, nombre: i.subsection_name }
                                      });
                                      setDataSeoStorage(i.labels_dto);
                                      }}>
                                      <ListItemText primary={i.subsection_name} />
                                    </ListItem>
                                  )
                                })}
                              </List>
                            )
                          })}
                        </div>
                      </Collapse>
                    </List>
                    : null}
                  {item.head.length > 0 && item.section_name == "Contáctanos" ?
                    <List key={item.id_section} className="nav">
                      <ListItem button style={{ display: item.enabled == true ? null : 'none' }} onClick={() => { handleClick(index) }}>
                        <ListItemText primary={item.section_name} />
                        {item.open ? <ExpandLess /> : <ExpandMore />}
                      </ListItem>
                      <Collapse in={item.open}>
                        <div className="inner-collapse-menu">
                          {item.head.map((e) => {
                            return (
                              <List>
                                <ListSubheader className="sub-heading" onMouseEnter={onMouseEnter2} onMouseLeave={onMouseLeave2}> {e.subsection_head_name}
                                </ListSubheader>
                                {e.subsection.map((i) => {
                                  return (
                                    <>
                                      {i.subsection_name == 'Preguntas Frecuentes' ?
                                        <Link style={{ textDecoration: 'none', color: 'inherit' }} to="/#faqs-section">
                                          <ListItem button style={{ width: '100%' }}
                                            onMouseEnter={onMouseEnter2}
                                            onMouseLeave={onMouseLeave2}
                                            onClick={() => {
                                              setMobileOpen(false);
                                              setDataSeoStorage(i.labels_dto);
                                            }}>
                                            <ListItemText primary={i.subsection_name} />
                                          </ListItem>
                                        </Link>
                                        :
                                        <ListItem button style={{ width: '100%' }}
                                          onMouseEnter={onMouseEnter2}
                                          onMouseLeave={onMouseLeave2}
                                          onClick={() => { 
                                            setMobileOpen(false);
                                            //history.push('/contactanos'); 
                                            history.push({
                                              pathname: `/${i.subsection_url}`,
                                              state: { detail: i.id_subsection, nombre: i.subsection_name }
                                            });
                                            setDataSeoStorage(i.labels_dto)}}>
                                          <ListItemText primary={i.subsection_name} />
                                        </ListItem>
                                      }
                                    </>
                                  )
                                })}
                              </List>
                            )
                          })}
                        </div>
                      </Collapse>
                    </List>
                    : null}
                  {item.head.length > 0 && item.section_name == "Servicios" ?
                    <List key={item.id_section} className="nav">
                      <ListItem button style={{ display: item.enabled == true ? null : 'none' }} onClick={() => { handleClick(index) }}>
                        <ListItemText primary={item.section_name} />
                        {item.open ? <ExpandLess /> : <ExpandMore />}
                      </ListItem>
                      <Collapse in={item.open}>
                        <div className="inner-collapse-menu">
                          {item.head.map((e) => {
                            return (
                              <List>
                                <ListSubheader className="sub-heading" onMouseEnter={onMouseEnter3} onMouseLeave={onMouseLeave3}> {e.subsection_head_name}
                                </ListSubheader>
                                {e.subsection.map((i) => {
                                  return (
                                    <ListItem button style={{ width: '100%' }}
                                      onMouseEnter={onMouseEnter3}
                                      onMouseLeave={onMouseLeave3}
                                      onClick={() => {
                                        history.push(`/detalle_linea/${i.labels_dto?.url}`);
                                        setMobileOpen(false);
                                        setDataSeoStorage(i.labels_dto);
                                      }}>
                                      <ListItemText primary={i.subsection_name} />
                                    </ListItem>
                                  )
                                })}
                              </List>
                            )
                          })}
                        </div>
                      </Collapse>
                    </List>
                    : null}
                  {item.head.length > 0 && item.section_name == "Responsabilidad Social" ?
                    <List key={item.id_section} className="nav">
                      <ListItem button style={{ display: item.enabled == true ? null : 'none' }} onClick={() => { handleClick(index) }}>
                        <ListItemText primary={item.section_name} />
                        {item.open ? <ExpandLess /> : <ExpandMore />}
                      </ListItem>
                      <Collapse in={item.open}>
                        <div className="inner-collapse-menu">
                          {item.head.map((e) => {
                            return (
                              <List>
                                <ListSubheader className="sub-heading" onMouseEnter={onMouseEnter5} onMouseLeave={onMouseLeave5}> {e.subsection_head_name}
                                </ListSubheader>
                                {e.subsection.map((i) => {
                                  if (i.subsection_url === 'respsocial_estructura') {
                                    if (showEstructura) {
                                      return (
                                        <ListItem button style={{ width: '100%' }}
                                          onMouseEnter={onMouseEnter5}
                                          onMouseLeave={onMouseLeave5}
                                          onClick={() => {
                                            history.push(`/${i.subsection_url}`);
                                            setMobileOpen(false);
                                            setDataSeoStorage(i.labels_dto);
                                          }}>
                                          <ListItemText primary={i.subsection_name} />
                                        </ListItem>
                                      )
                                    }
                                  } else {
                                    return (
                                      <ListItem button style={{ width: '100%' }}
                                        onMouseEnter={onMouseEnter5}
                                        onMouseLeave={onMouseLeave5}
                                        onClick={() => {
                                          history.push(`/${i.subsection_url}`);
                                          setMobileOpen(false);
                                          setDataSeoStorage(i.labels_dto);
                                        }}>
                                        <ListItemText primary={i.subsection_name} />
                                      </ListItem>
                                    )
                                  }
                                })}
                              </List>
                            )
                          })}
                        </div>
                      </Collapse>
                    </List>
                    : null}
                </>
              ))}
            </List>
          </div>
          <div className="col-sm-12 mb-2">
            {redes.map((e) => (
              <IconButton onClick={() => {
                if (validateURL(e.url)) {
                  window.open(e.url)
                } else {
                  window.open('https://' + e.url)
                }
              }} color="primary" aria-label="menu" className="icon-social-button">
                <img className="icon-social-mobile" alt={e.alt} title={e.title}
                  src={`${mainUrl}/azure-storage/api/blob/download/?file=${e.name_image}`} />
              </IconButton>
            ))}
          </div>
          {/* <div className="col-sm-12">
            <IconButton aria-label="button-menu" onClick={() => { window.open('http://portalredb2c-qa.azurewebsites.net/', '_blank'); }} className="buttonMobile" color="inherit">
              <PersonIcon style={{ marginRight: 10, color: 'white', fontSize: 14 }} className="RobotoBold" />portal clientes
            </IconButton>
          </div> */}
        </div>
      </div>
    </div>
  );
  return (
    <>
    <AppBar position="relative" className="appBar">
      <CssBaseline />
      {matches ? null
        : <Toolbar1 />}
      <div className="header-main">
        <Drawer aria-label="button-menu-mobile" anchor={'right'} container={container} variant="temporary" open={mobileOpen} onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
        >{drawer}
        </Drawer>
        <div className="container">
          <div className="row">
            <div className="col-sm-3 col-xs-6 col-6 mt-2 mb-2">
              <a href="javascript:;" onClick={() => {  history.push('/') }} style={{ cursor: 'pointer' }}>
                <img 
                style={{ cursor: 'pointer', maxHeight: '60px' }}
                  src={require('./images/logo_rosa_dos.png').default}
                  title="Red ambiental" 
                  alt="Red ambiental" 
                  className="logo" width="130" height="100"
                />
              </a>
            </div>
            <div className="col-sm-5 col-xs-5 col-5 v-center">
              {data.map((item, index) => {
                return (
                  <>
                    {item.head.length > 0 && item.section_name == "Quienes Somos" ?
                      <List key={item.id_section} className="nav">
                        <li>
                          <ListItem button style={{ display: item.enabled == true ? null : "none" }} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
                            <ListItemText primary={item.section_name} />
                          </ListItem>
                          <Collapse timeout={'auto'} in={dropdown}>
                            <div onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} style={{ flexDirection: 'column', display: 'block' }} className="inner-collapse-menu">
                              {item.head.map((e) => {
                                return (
                                  <List>
                                    {e.subsection.map((i) => {
                                      return (
                                        <>
                                          {i.subsection_url == 'presencia_nacional' ?
                                            <Link style={{ textDecoration: 'none' }} to="/#map-section">
                                              <ListItem button style={{ width: '100%' }}
                                              // onMouseEnter={onMouseEnter}
                                              // onMouseLeave={onMouseLeave}
                                              >
                                                <ListItemText primary={i.subsection_name} />
                                              </ListItem>
                                            </Link>
                                            :
                                            <ListItem button style={{ width: '100%' }}
                                              // onMouseEnter={onMouseEnter}
                                              // onMouseLeave={onMouseLeave}
                                              onClick={() => {
                                                setDataSeoStorage(i.labels_dto);
                                                if (i.subsection_url == 'informes_anuales') {
                                                  history.push({
                                                    pathname: `/${i.subsection_url}`,
                                                    state: { detail: i.id_subsection, nombre: i.subsection_name }
                                                  })
                                                } else {
                                                    if(i.subsection_url == 'presencia_nacional'){
                                                      history.push(`/#map-section`)
                                                    }else{
                                                      history.push(`/nuestra_empresa/${encodeURIComponent(i.labels_dto.url)}`)
                                                    }
                                                  }
                                                //id_subsection: 72
                                                // subsection_name: "Misión"
                                                // subsection_url: "misión"
                                                // history.push({
                                                //   pathname:`/${i.subsection_url}`,
                                                //   state: { detail: i.id_subsection,nombre:i.subsection_name }})

                                              }}>
                                              <ListItemText primary={i.subsection_name} />
                                            </ListItem>
                                          }
                                        </>
                                      )
                                    })}
                                  </List>
                                )
                              })}
                            </div>
                          </Collapse>
                        </li>
                      </List>
                      : <List style={{
                        display: item.section_name == "Noticias" || item.section_name == "Servicios" || item.section_name == "Contáctanos"
                          || item.section_name == "Responsabilidad Social" || item.section_name == "Bolsa de trabajo"
                          ? 'none' : null
                      }} key={item.id_section} className="nav">
                        <li>
                          <ListItem button onClick={() => {history.push(`/${item.section_url}`); setDataSeoStorage(item.labels_dto);}} style={{ display: item.enabled == true ? null : "none" }}>
                            {item.section_name == "Noticias" || item.section_name == "Servicios" || item.section_name == "Contáctanos"
                              || item.section_name == "Responsabilidad Social" || item.section_name == "Bolsa de trabajo" ? null
                              :
                              <ListItemText primary={item.section_name} />
                            }

                          </ListItem>
                        </li>
                      </List>}
                    {item.head.length > 0 && item.section_name == "Noticias" ?
                      <List key={item.id_section} className="nav">
                        <li>
                          <ListItem button style={{ display: item.enabled == true ? null : "none" }} onMouseEnter={onMouseEnter2} onMouseLeave={onMouseLeave2}>
                            <ListItemText primary={item.section_name} />
                          </ListItem>
                          <Collapse timeout={'auto'} in={dropdown2}>
                            <div onMouseEnter={onMouseEnter2} onMouseLeave={onMouseLeave2} className="inner-collapse-menu">
                              {item.head.map((e) => {
                                return (
                                  <List>
                                    {e.subsection.map((i) => {
                                      return (
                                        <ListItem button style={{ width: '100%' }}
                                          // onMouseEnter={onMouseEnter2}
                                          // onMouseLeave={onMouseLeave2}
                                          onClick={() => {history.push({
                                            pathname: `/${i.subsection_url}`,
                                            state: { detail: i.id_subsection, nombre: i.subsection_name }
                                          });
                                          setDataSeoStorage(i.labels_dto);
                                          }}>
                                          <ListItemText primary={i.subsection_name} />
                                        </ListItem>
                                      )
                                    })}
                                  </List>
                                )
                              })}
                            </div>
                          </Collapse>
                        </li>
                      </List>
                      : null}
                    {item.head.length > 0 && item.section_name == "Contáctanos" ?
                      <List key={item.id_section} className="nav">
                        <li>
                          <ListItem button style={{ display: item.enabled == true ? null : "none" }} onMouseEnter={onMouseEnter4} onMouseLeave={onMouseLeave4}>
                            <ListItemText primary={item.section_name} />
                          </ListItem>
                          <Collapse timeout={'auto'} in={dropdown4}>
                            <div onMouseEnter={onMouseEnter4} onMouseLeave={onMouseLeave4} className="inner-collapse-menu">
                              {item.head.map((e) => {
                                return (
                                  <List>
                                    {e.subsection.map((i) => {
                                      return (
                                        <>
                                          {i.subsection_name == 'Preguntas Frecuentes' ?
                                            <Link style={{ textDecoration: 'none' }} to="/#faqs-section">
                                              <ListItem button style={{ width: '100%' }}
                                              // onMouseEnter={onMouseEnter4}
                                              // onMouseLeave={onMouseLeave4}
                                              onClick={() => {
                                                setDataSeoStorage(i.labels_dto);
                                              }}
                                              >
                                                <ListItemText primary={i.subsection_name} />
                                              </ListItem>
                                            </Link>
                                            :
                                            <ListItem button style={{ width: '100%' }} id={i.subsection_name == "Contacto" && "contacto"}
                                              // onMouseEnter={onMouseEnter4}
                                              // onMouseLeave={onMouseLeave4}
                                              onClick={() => {
                                                if (i.subsection_name == "Contacto") {
                                                  //history.push('/contactanos')
                                                  history.push({
                                                    pathname: `/${i.subsection_url}`,
                                                    state: { detail: i.id_subsection, nombre: i.subsection_name }
                                                  });
                                                }
                                                else if (i.subsection_name == "Preguntas Frecuentes") {

                                                }

                                                setDataSeoStorage(i.labels_dto);

                                              }}>
                                              <ListItemText primary={i.subsection_name} />
                                            </ListItem>
                                          }

                                        </>
                                      )
                                    })}
                                  </List>
                                )
                              })}
                            </div>
                          </Collapse>
                        </li>
                      </List>
                      : null}
                    {item.head.length > 0 && item.section_name == "Servicios" ?
                      <List key={item.id_section} className="nav">
                        <li>
                          <ListItem button style={{ display: item.enabled == true ? null : "none" }} onMouseEnter={onMouseEnter3} onMouseLeave={onMouseLeave3}>
                            <ListItemText primary={item.section_name} />
                          </ListItem>
                          <Collapse timeout={'auto'} in={dropdown3}>
                            <div onMouseEnter={onMouseEnter3} onMouseLeave={onMouseLeave3} className="inner-collapse-menu">
                              {item.head.map((e) => {
                                return (
                                  <List>
                                    {e.subsection.map((i) => {
                                      return (
                                        <ListItem button style={{ width: '100%' }}
                                          // onMouseEnter={onMouseEnter3}
                                          // onMouseLeave={onMouseLeave3}
                                          onClick={() => { history.push(`/detalle_linea/${i.labels_dto?.url}`); setDataSeoStorage(i.labels_dto);}}>
                                          <ListItemText primary={i.subsection_name} />
                                        </ListItem>
                                      )
                                    })}
                                  </List>
                                )
                              })}
                            </div>
                          </Collapse>
                        </li>
                      </List>
                      : null}
                    {item.head.length > 0 && item.section_name == "Responsabilidad Social" ?
                      <List key={item.id_section} className="nav">
                        <li>
                          <ListItem button style={{ display: item.enabled == true ? null : "none" }} onMouseEnter={onMouseEnter5} onMouseLeave={onMouseLeave5}>
                            <ListItemText primary={item.section_name} />
                          </ListItem>
                          <Collapse timeout={'auto'} in={dropdown5}>
                            <div onMouseEnter={onMouseEnter5} onMouseLeave={onMouseLeave5} className="inner-collapse-menu">
                              {item.head.map((e) => {
                                return (
                                  <List>
                                    {e.subsection.map((i) => {
                                      if (i.subsection_url === 'respsocial_estructura') {
                                        if (showEstructura) {
                                          return (
                                            <ListItem button style={{ width: '100%' }}
                                              // onMouseEnter={onMouseEnter2}
                                              // onMouseLeave={onMouseLeave2}
                                              onClick={() => {history.push({
                                                pathname: `/${i.subsection_url}`,
                                                state: { detail: i.id_subsection, nombre: i.subsection_name }
                                              }); setDataSeoStorage(i.labels_dto);}}>
                                              <ListItemText primary={i.subsection_name} />
                                            </ListItem>
                                          )
                                        }
                                      } else {
                                        return (
                                          <ListItem button style={{ width: '100%' }}
                                            // onMouseEnter={onMouseEnter2}
                                            // onMouseLeave={onMouseLeave2}
                                            onClick={() => {history.push({
                                              pathname: `/${i.subsection_url}`,
                                              state: { detail: i.id_subsection, nombre: i.subsection_name }
                                            }); setDataSeoStorage(i.labels_dto);}}>
                                            <ListItemText primary={i.subsection_name} />
                                          </ListItem>
                                        )
                                      }
                                    })}
                                  </List>
                                )
                              })}
                            </div>
                          </Collapse>
                        </li>
                      </List>
                      : null}
                  </>
                );
              })}
              {matches ?
                <IconButton
                  className="menu-toggle"
                  size="large"
                  edge="end"
                  color="primary"
                  onClick={handleDrawerToggle}
                >
                  <MenuIcon
                    style={{ marginRight: 10 }} />
                </IconButton> : null}
            </div>
            <div className="col-sm-4 mt-4 text-end">
                <img style={{ cursor: 'pointer', maxHeight: '50px', maxWidth: '100px'}}
                  src={require('./images/ESR.png').default}
                  title="Red ambiental" className="logo"
                />
                <img style={{ display: 'none', cursor: 'pointer', maxHeight: '50px', maxWidth: '35px', marginLeft: '10%' }}
                  src={require('./images/GPTW.png').default}
                  title="Red ambiental" className="logo"
                />
            </div>
          </div>
        </div>
      </div>
    </AppBar>
    </>
    
  )
}
