import React, { useEffect } from 'react';
import Swiper, { Navigation, Pagination } from 'swiper';
import { saveAs } from "file-saver";
import 'swiper/swiper-bundle.min.css';
import './styles.scss';

const mainUrl = process.env.REACT_APP_API_GATEWAY_URL;
const SwiperManual = ({ keyWord, pdfList }) => {

  const saveFile = (e) => {
    saveAs(e);
  };

  const callFuncSwiper = () => {
    new Swiper('.swiper', {
      modules: [Navigation, Pagination],
      slidesPerView: 1,
      spaceBetween: 30,
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
      },
      autoplay: {
        delay: 3000,
        disableOnInteraction: false,
      },
      breakpoints: {
        768: {
          slidesPerView: 3,
        },
      },
    });
  }

  useEffect(() => {
    callFuncSwiper();
  }, []);

  return (
    <div className="swiper">
      <div className="swiper-wrapper">
        {pdfList?.length && pdfList.map((item, key) => {
          return (
            <div className="swiper-slide" key={key}>
              <div
                className='img-pdf'
              >
                <h3>{item?.report_name}</h3>
                <img
                  src={`${mainUrl}/azure-storage/api/blob/download/?file=${item?.name_cover_image}`} alt={item?.name_cover_image}
                  onClick={() => {
                    saveFile(`${mainUrl}/azure-storage/api/blob/download/?file=${item?.url_report_file}`);
                  }}
                  style={{ cursor: 'pointer' }}
                />
              </div>
            </div>)
        })

        }
      </div>
      <div className="swiper-button-next"></div>
      <div className="swiper-button-prev"></div>
      <div className="swiper-pagination"></div>
    </div>
  );
};

export default SwiperManual;
