/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import ReactPlayer from 'react-player';
import { useHistory } from 'react-router-dom'
import Slider from "./Slider/Slider";
import { getMainContent, getPrograms, getReports } from "./Requests/RequestResp";
import './styles.scss';

const mainUrl = process.env.REACT_APP_API_GATEWAY_URL
const Sostenibilidad = () => {
  const history = useHistory();
  const [pdfList, setPdfList] = useState([]);
  const [pdfListFilter, setPdfListFilter] = useState([]);
  const [wordsSearch, setWordsSearch] = useState('');
  const [content, setContent] = useState({});
  const [programs, setPrograms] = useState([]);
  const [searching, setSearching] = useState(false);

  const contentHTML = (htmlString) => {
    if (htmlString)
      return (
        <div
          dangerouslySetInnerHTML={{ __html: htmlString }}
        />
      );
  };

  useEffect(() => {
    if (wordsSearch && pdfList?.length) {
      const filter = pdfList.filter(i => i?.report_name?.toLowerCase().includes(wordsSearch.toLowerCase()));
      setPdfListFilter(filter)
      setSearching(true);
      setTimeout(() => {
        setSearching(false);
      }, 200);
    } else if (!wordsSearch) {
      setPdfListFilter(pdfList)
      setSearching(true);
      setTimeout(() => {
        setSearching(false);
      }, 200);
    }
  }, [wordsSearch]);

  useEffect(() => {
    const getContent = async () => {
      const response = await getMainContent();
      if (response) {
        setContent(response);
      }
    }

    const getProgramsList = async () => {
      const response = await getPrograms();
      if (response) setPrograms(response)
    }

    const getReportsList = async () => {
      const response = await getReports();
      if (response) {
        setPdfList(response)
        setPdfListFilter(response)
      }
    }

    getContent();
    getProgramsList();
    getReportsList();
  }, []);

  return (
    <>
      <div
        className="banner-sostenibilidad"
        style={{
          background: `url(${content?.media?.length && content?.media[0] && mainUrl + '/azure-storage/api/blob/download/?file=' + content?.media[0]?.url_media})`
        }}>
      </div>
      <div className="div-video">
        <div className="title-video">
          <h1>{contentHTML(content?.content)}</h1>
        </div>
        <div className="file-video">
          <ReactPlayer controls width={"100%"} height={"100%"} url={content?.media?.length && content?.media[0] && content?.media[1]?.url_media !== null ? content?.media[1]?.url_media : null} />
        </div>
      </div>
      <div className="div-desc-list">
        <div className="div-description-text">
          <p>{contentHTML(content?.description)}</p>
        </div>
        <div className="div-list">
          {programs?.length && programs.map((item, key) => {
            return (
              <div className="item-list" key={key}>
                {item?.name_cover_photo &&
                  <div
                    style={{
                      textAlign: item?.url ? 'left' : 'center'
                    }}
                  >
                    <img src={`${mainUrl}/azure-storage/api/blob/download/?file=${item.cover_photo_url}`} alt={item?.name_cover_photo} />
                  </div>
                }
                {item?.url &&
                  <div
                    className={`div-video-item ${item?.name_cover_photo ? 'div-video-main' : 'div-video-alone'}`}
                    style={{
                      textAlign: item?.name_cover_photo ? 'left' : 'center'
                    }}
                  >
                    <ReactPlayer
                      controls
                      width={"100%"}
                      style={{
                        boxShadow: '10px 10px 20px 0px #a4a4a4'
                      }}
                      url={item?.url !== null ? item?.url : null}
                    />
                  </div>
                }
              </div>
            )
          })}
        </div>
      </div>

      <div className="div-pdflist">
        <div className="div-search">
          {content?.title_reports && contentHTML(content?.title_reports)}
          <input type="text" className="mt-4 form-control" placeholder="Buscar informe" value={wordsSearch} onChange={(e) => { setWordsSearch(e.target.value) }} />
        </div>
        {!!pdfListFilter?.length && !searching &&
          <Slider keyWord={wordsSearch} pdfList={pdfListFilter} />
        }
        {searching &&
          <div style={{ minHeight: 500 }} >
            <div className="skeleton-container">
              <div className="skeleton-item"></div>
              <div className="skeleton-item"></div>
              <div className="skeleton-item"></div>
            </div>
          </div>
        }
      </div>

      <div className="div-contact-form">
        <div className="cta-contact-form">
          {content?.title_contacts && contentHTML(content?.title_contacts)}
        </div>
        <button className="button btn-action-primary btn-footer mb-3" onClick={() => { history.push('contacto?o=sostenibilidad') }}>
          Ir a contacto
        </button>
      </div>
    </>
  )
};

export default Sostenibilidad;