/** @format */

import { Switch, Route, useHistory, Redirect } from "react-router-dom";
import Main from "../components/Main";
import InfoInstitucional from "../pages/InfoInst/InfoInstitucional";
import Blog from "../pages/blog/blog";
import Noticias from "../pages/blog/noticiasList";
import Contacto from "../pages/contacto/contacto";
import Cotizador from "../pages/cotizador/cotizador";
import BolsaT from "../components/vacantes/BolsaT";
import Vacantes from "../components/vacantes/Vacantes";
import Aviso from "../components/AvisoP/Aviso";
import Informeanual from "../pages/Infanual/Informeanual";
import RespSocial from "../pages/RespSocial/RespSocial";
import VerInforme from "../pages/Infanual/verInforme";
import ListaPrensa from "../pages/prensa/ListaPrensa";
import DetallePrensa from "../pages/prensa/DetallePrensa";
import DetallePregunta from "../pages/preguntas/detallePreg";
import VerResp from "../pages/RespSocial/verRespSocial";
import Services from "../pages/servicios/Services";
import Sucursales from "../pages/Sucursales/Sucursales";
import DetalleLinea from "../components/lnegocio/DetalleLinea";
import Search from "../pages/search/searchList";
import RespSocialEstructura from "../pages/RespSocial/Estructura/Estructura";
import SolicitaCotizacion from "../pages/SolicitaCotizacion/SolicitaCotizacion";
import Sostenibilidad from "../pages/Sostenibilidad/Sostenibilidad";

function App() {
  const history = useHistory();
  return (
    <Switch>
      <Route exact path='/'>
        <Main />
      </Route>
      <Route path='/nuestra_empresa/:name'>
        <InfoInstitucional />
      </Route>
      <Route path='/Blog_Detalle/:name'>
        <Blog />
      </Route>
      <Route path='/blog'>
        <Noticias />
      </Route>
      <Route path='/contactanos'>
        <Contacto />
      </Route>
      <Route path='/contacto'>
        <Contacto />
      </Route>
      <Route path='/bolsa_trabajo'>
        <BolsaT />
      </Route>
      <Route path='/vacantes'>
        <Vacantes />
      </Route>
      <Route path='/aviso_privacidad'>
        <Aviso />
      </Route>
      <Route path='/cotizador'>
        <Cotizador />
      </Route>
      <Route path='/solicita_cotizacion'>
        <SolicitaCotizacion />
      </Route>
      <Route path='/informes_anuales'>
        <Informeanual />
      </Route>
      <Route path='/verReporte_anual/:name'>
        <VerInforme />
      </Route>
      <Route path='/responsabilidad_social'>
        <RespSocial />
      </Route>
      <Route path='/respsocial_estructura'>
        <RespSocialEstructura />
      </Route>
      {/* <Route path='/verReporte_anual'>
      <VerInforme/>
    </Route> */}
      <Route path='/prensa'>
        <ListaPrensa />
      </Route>
      {/* <Route path='/detalle_prensa/:id'>
      <DetallePrensa />
    </Route> */}
      <Route path='/detalle_prensa/:name'>
        <DetallePrensa />
      </Route>
      {/* <Route path='/detalle_linea/:id'>
      <DetalleLinea />
    </Route> */}
      <Route path='/detalle_linea/:name'>
        <DetalleLinea />
      </Route>
      <Route path='/detalle_pregunta'>
        <DetallePregunta />
      </Route>
      <Route path='/resp_detalle/:id'>
        <VerResp />
      </Route>
      <Route path='/servicios/:route/:name?'>
        <Services />
      </Route>
      <Route path='/servicios'>
        <Services />
      </Route>
      <Route exact path='/sucursales/:name/'>
        <Sucursales />
      </Route>
      <Route exact path='/sucursales/:name/:zoneName?/'>
        <Sucursales />
      </Route>
      <Route path='/busqueda'>
        <Search />
      </Route>
      <Route path='/sostenibilidad'>
        <Sostenibilidad />
      </Route>


      <Route exact path='/category/san-luis-potosi/'>
        {() => {
          if (window.location.pathname === "/category/san-luis-potosi/") history.push(`/sucursales/red-ambiental-sucursal-san-luis-potosi`);
        }}
      </Route>
      <Route exact path='/category/campeche/'>
        {() => {
          if (window.location.pathname === "/category/campeche/") history.push(`/sucursales/red-ambiental-sucursal-campeche`);
        }}
      </Route>
      <Route exact path='/category/durango/'>
        {() => {
          if (window.location.pathname === "/category/durango/") history.push(`/sucursales/red-ambiental-sucursal-durango`);
        }}
      </Route>
      <Route exact path='/tag/queretaro/'>
        {() => {
          if (window.location.pathname === "/tag/queretaro/") history.push(`/sucursales/red-ambiental-sucursal-queretaro`);
        }}
      </Route>
      <Route exact path='/tag/vigue/'>
        {() => {
          if (window.location.pathname === "/tag/vigue/") history.push(`/detalle_linea/centro-integral--procesamiento-residuo`);
        }}
      </Route>
      <Route exact path='/tag/san-luis-potosi/'>
        {() => {
          if (window.location.pathname === "/tag/san-luis-potosi/") history.push(`/sucursales/red-ambiental-sucursal-san-luis-potosi`);
        }}
      </Route>
      <Route exact path='/category/nuevo-leon/'>
        {() => {
          if (window.location.pathname === "/category/nuevo-leon/") history.push(`/contactanos`);
        }}
      </Route>
      <Route exact path='/campeche/'>
        {() => {
          if (window.location.pathname === "/campeche/") history.push(`/sucursales/red-ambiental-sucursal-campeche`);
        }}
      </Route>
      <Route exact path='/celebra-red-ambiental-en-san-luis-potosi-20-anos-de-su-fundacion/'>
        {() => {
          if (window.location.pathname === "/celebra-red-ambiental-en-san-luis-potosi-20-anos-de-su-fundacion/")
            history.push(`/sucursales/red-ambiental-sucursal-san-luis-potosi`);
        }}
      </Route>
      <Route exact path='/tag/red-ambiental-durango/'>
        {() => {
          if (window.location.pathname === "/tag/red-ambiental-durango/") history.push(`/sucursales/red-ambiental-sucursal-durango`);
        }}
      </Route>
      <Route exact path='/inicia-red-ambiental-operaciones-en-queretaro/'>
        {() => {
          if (window.location.pathname === "/inicia-red-ambiental-operaciones-en-queretaro/")
            history.push(`/sucursales/red-ambiental-sucursal-queretaro`);
        }}
      </Route>
      <Route exact path='/primer-concurso-de-dibujo-infantil-los-detectives-ambientales-pintando-un-mundo-mejor/'>
        {() => {
          if (window.location.pathname === "/primer-concurso-de-dibujo-infantil-los-detectives-ambientales-pintando-un-mundo-mejor/")
            history.push(`/blog`);
        }}
      </Route>
      <Route exact path='/tag/base-de-operaciones/'>
        {() => {
          if (window.location.pathname === "/tag/base-de-operaciones/") history.push(`/blog`);
        }}
      </Route>
      <Route exact path='/se-puede-comprar-cytotec-sin-receta-en-mexico/'>
        {() => {
          if (window.location.pathname === "/se-puede-comprar-cytotec-sin-receta-en-mexico/") history.push(`/`);
        }}
      </Route>
      <Route exact path='/disposicion-final/'>
        {() => {
          if (window.location.pathname === "/disposicion-final/") history.push(`/detalle_linea/centro-integral--procesamiento-residuo`);
        }}
      </Route>
      <Route exact path='/se-puede-comprar-cytotec-sin-receta-en-mexico/'>
        {() => {
          if (window.location.pathname === "/se-puede-comprar-cytotec-sin-receta-en-mexico/") history.push(`/`);
        }}
      </Route>
      <Route exact path='/inicia-red-ambiental-operaciones-en-cancun/'>
        {() => {
          if (window.location.pathname === "/inicia-red-ambiental-operaciones-en-cancun/") history.push(`/sucursales/red-ambiental-sucursal-cancun`);
        }}
      </Route>
      <Route exact path='/recoleccion-tratamiento-rpbi/'>
        {() => {
          if (window.location.pathname === "/recoleccion-tratamiento-rpbi/") history.push(`/detalle_linea/servicio-recoleccion-resiudos-clinicos`);
        }}
      </Route>
      <Route exact path='/tegretol-nombre-generico/'>
        {() => {
          if (window.location.pathname === "/tegretol-nombre-generico/") history.push(`/`);
        }}
      </Route>
      <Route exact path='/category/durango/'>
        {() => {
          if (window.location.pathname === "/category/durango/") history.push(`/sucursales/red-ambiental-sucursal-durango`);
        }}
      </Route>
      <Route exact path='/category/queretaro/'>
        {() => {
          if (window.location.pathname === "/category/queretaro/") history.push(`/sucursales/red-ambiental-sucursal-queretaro`);
        }}
      </Route>
      <Route exact path='/inicia-red-ambiental-operaciones-en-juarez-nuevo-leon/'>
        {() => {
          if (window.location.pathname === "/inicia-red-ambiental-operaciones-en-juarez-nuevo-leon/") history.push(`/`);
        }}
      </Route>
      <Route exact path='/recoleccion-tratamiento-rpbi/'>
        {() => {
          if (window.location.pathname === "/recoleccion-tratamiento-rpbi/") history.push(`/detalle_linea/servicio-recoleccion-resiudos-clinicos`);
        }}
      </Route>
      <Route exact path='tag/relleno-sanitario/'>
        {() => {
          if (window.location.pathname === "/tag/relleno-sanitario/") history.push(`/detalle_linea/centro-integral--procesamiento-residuo`);
        }}
      </Route>

      <Route exact path='/recoleccion-tratamiento-rpbi/'>
        {() => {
          if (window.location.pathname === "/recoleccion-tratamiento-rpbi/") history.push(`/detalle_linea/servicio-recoleccion-resiudos-clinicos`);
        }}
      </Route>
      <Route exact path='/recoleccion-tratamiento-rpbi/'>
        {() => {
          if (window.location.pathname === "/recoleccion-tratamiento-rpbi/") history.push(`/detalle_linea/servicio-recoleccion-resiudos-clinicos`);
        }}
      </Route>
      <Route exact path='/recoleccion-tratamiento-rpbi/'>
        {() => {
          if (window.location.pathname === "/recoleccion-tratamiento-rpbi/") history.push(`/detalle_linea/servicio-recoleccion-resiudos-clinicos`);
        }}
      </Route>
      <Route exact path='/inicia-red-ambiental-operaciones-en-queretaro/'>
        {() => {
          if (window.location.pathname === "/inicia-red-ambiental-operaciones-en-queretaro/")
            history.push(`/sucursales/red-ambiental-sucursal-queretaro`);
        }}
      </Route>
      <Route exact path='/recoleccion-tratamiento-rpbi/'>
        {() => {
          if (window.location.pathname === "/recoleccion-tratamiento-rpbi/") history.push(`/detalle_linea/servicio-recoleccion-resiudos-clinicos`);
        }}
      </Route>
      <Route exact path='/recoleccion-tratamiento-rpbi/'>
        {() => {
          if (window.location.pathname === "/recoleccion-tratamiento-rpbi/") history.push(`/detalle_linea/servicio-recoleccion-resiudos-clinicos`);
        }}
      </Route>
      <Route exact path='/nuevas-rutas-de-recoleccion-en-queretaro/'>
        {() => {
          if (window.location.pathname === "/nuevas-rutas-de-recoleccion-en-queretaro/") history.push(`/sucursales/red-ambiental-sucursal-queretaro`);
        }}
      </Route>
      <Route exact path='/recoleccion-tratamiento-rpbi/'>
        {() => {
          if (window.location.pathname === "/recoleccion-tratamiento-rpbi/") history.push(`/detalle_linea/servicio-recoleccion-resiudos-clinicos`);
        }}
      </Route>
      <Route exact path='/recoleccion-tratamiento-rpbi/'>
        {() => {
          if (window.location.pathname === "/recoleccion-tratamiento-rpbi/") history.push(`/detalle_linea/servicio-recoleccion-resiudos-clinicos`);
        }}
      </Route>
      <Route exact path='/certifica-profepa-a-vigue-relleno-sanitario/'>
        {() => {
          if (window.location.pathname === "/certifica-profepa-a-vigue-relleno-sanitario/") history.push(`/`);
        }}
      </Route>
      <Route exact path='/recoleccion-tratamiento-rpbi/'>
        {() => {
          if (window.location.pathname === "/recoleccion-tratamiento-rpbi/") history.push(`/detalle_linea/servicio-recoleccion-resiudos-clinicos`);
        }}
      </Route>
      <Route exact path='/mejora-el-servicio-de-recoleccion-de-basura-en-san-luis-potosi/'>
        {() => {
          if (window.location.pathname === "/mejora-el-servicio-de-recoleccion-de-basura-en-san-luis-potosi/")
            history.push(`/sucursales/red-ambiental-sucursal-san-luis-potosi`);
        }}
      </Route>
      <Route exact path='/tag/campeche/'>
        {() => {
          if (window.location.pathname === "/tag/campeche/") history.push(`/sucursales/red-ambiental-sucursal-campeche`);
        }}
      </Route>
      <Route exact path='/recoleccion-tratamiento-rpbi/'>
        {() => {
          if (window.location.pathname === "/recoleccion-tratamiento-rpbi/") history.push(`/detalle_linea/servicio-recoleccion-resiudos-clinicos`);
        }}
      </Route>
      <Route exact path='/consulta-el-horario-de-recoleccion-en-tu-colonia/'>
        {() => {
          if (window.location.pathname === "/consulta-el-horario-de-recoleccion-en-tu-colonia/")
            history.push(`/detalle_linea/servicio-recoleccion-residuos-domiciliaria`);
        }}
      </Route>
      <Route exact path='/recoleccion-tratamiento-rpbi/'>
        {() => {
          if (window.location.pathname === "/recoleccion-tratamiento-rpbi/") history.push(`/detalle_linea/servicio-recoleccion-resiudos-clinicos`);
        }}
      </Route>
      <Route exact path='/consulta-el-horario-de-recoleccion-en-tu-colonia/'>
        {() => {
          if (window.location.pathname === "/consulta-el-horario-de-recoleccion-en-tu-colonia/")
            history.push(`/sucursales/red-ambiental-sucursal-zona-metropolitana-monterrey`);
        }}
      </Route>
      <Route exact path='/san-luis/'>
        {() => {
          if (window.location.pathname === "/san-luis/") history.push(`/sucursales/red-ambiental-sucursal-san-luis-potosi`);
        }}
      </Route>
      <Route exact path='/tag/recoleccion-de-basura/'>
        {() => {
          if (window.location.pathname === "/tag/recoleccion-de-basura/") history.push(`/`);
        }}
      </Route>
      <Route exact path='/cipro-xr-de-500-mg/'>
        {() => {
          if (window.location.pathname === "/cipro-xr-de-500-mg/") history.push(`/`);
        }}
      </Route>
      <Route exact path='/inicia-red-ambiental-operaciones-en-juarez-nuevo-leon/'>
        {() => {
          if (window.location.pathname === "/inicia-red-ambiental-operaciones-en-juarez-nuevo-leon/") history.push(`/`);
        }}
      </Route>
      <Route exact path='/category/leon/'>
        {() => {
          if (window.location.pathname === "/category/leon/") history.push(`/`);
        }}
      </Route>
      <Route exact path='/tag/campeche/'>
        {() => {
          if (window.location.pathname === "/tag/campeche/") history.push(`/sucursales/red-ambiental-sucursal-campeche`);
        }}
      </Route>
      <Route exact path='/inicia-red-ambiental-operaciones-en-queretaro/'>
        {() => {
          if (window.location.pathname === "/inicia-red-ambiental-operaciones-en-queretaro/")
            history.push(`/sucursales/red-ambiental-sucursal-queretaro`);
        }}
      </Route>
      <Route exact path='/san-luis/'>
        {() => {
          if (window.location.pathname === "/san-luis/") history.push(`/sucursales/red-ambiental-sucursal-san-luis-potosi`);
        }}
      </Route>
      <Route exact path='/consulta-el-horario-de-recoleccion-en-tu-colonia/'>
        {() => {
          if (window.location.pathname === "/consulta-el-horario-de-recoleccion-en-tu-colonia/")
            history.push(`/detalle_linea/servicio-recoleccion-residuos-domiciliaria`);
        }}
      </Route>
      <Route exact path='/recoleccion-tratamiento-rpbi/'>
        {() => {
          if (window.location.pathname === "/recoleccion-tratamiento-rpbi/") history.push(`/detalle_linea/servicio-recoleccion-resiudos-clinicos`);
        }}
      </Route>
      <Route exact path='/recoleccion-tratamiento-rpbi/'>
        {() => {
          if (window.location.pathname === "/recoleccion-tratamiento-rpbi/") history.push(`/detalle_linea/servicio-recoleccion-resiudos-clinicos`);
        }}
      </Route>
      <Route exact path='/primer-concurso-de-dibujo-infantil-los-detectives-ambientales-pintando-un-mundo-mejor/'>
        {() => {
          if (window.location.pathname === "/primer-concurso-de-dibujo-infantil-los-detectives-ambientales-pintando-un-mundo-mejor/")
            history.push(`/responsabilidad_social`);
        }}
      </Route>
      <Route exact path='/cheap-lisinopril-no-prescription-z/'>
        {() => {
          if (window.location.pathname === "/cheap-lisinopril-no-prescription-z/") history.push(`/`);
        }}
      </Route>
      <Route exact path='/inicia-red-ambiental-operaciones-en-juarez-nuevo-leon/'>
        {() => {
          if (window.location.pathname === "/inicia-red-ambiental-operaciones-en-juarez-nuevo-leon/")
            history.push(`/detalle_linea/servicio-recoleccion-residuos-domiciliaria`);
        }}
      </Route>
      <Route exact path='/san-luis/'>
        {() => {
          if (window.location.pathname === "/san-luis/") history.push(`/sucursales/red-ambiental-sucursal-san-luis-potosi`);
        }}
      </Route>
      <Route exact path='/industrial-y-comercial/'>
        {() => {
          if (window.location.pathname === "/industrial-y-comercial/")
            history.push(`/detalle_linea/servicio-transporte-residuos-industrial-comercial`);
        }}
      </Route>
      <Route exact path='/recoleccion-tratamiento-rpbi/'>
        {() => {
          if (window.location.pathname === "/recoleccion-tratamiento-rpbi/") history.push(`/detalle_linea/servicio-recoleccion-resiudos-clinicos`);
        }}
      </Route>
      <Redirect to='/error/404' />
    </Switch>
  );
}

export default App;
