import React, { useState, useEffect, useRef } from 'react';
import { IconButton } from '@mui/material';
import { estados } from './estados/estados'
import { ciudades } from './ciudades/ciudades'
import Requests from './Request/Requests'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Phone from '@mui/icons-material/PhoneOutlined';
import Email from '@mui/icons-material/EmailOutlined';
import Location from '@mui/icons-material/LocationOnOutlined';
import { getMsgSostenibilidad } from './Request/Requests';

function Contacto() {
  const [active, setActive] = useState(false)
  const [errorT1, setErrorT1] = useState(false)
  const [errorT2, setErrorT2] = useState(false)
  const [errorT3, setErrorT3] = useState(false)
  const [errorT4, setErrorT4] = useState(false)
  const [errorT5, setErrorT5] = useState(false)
  const [errorT6, setErrorT6] = useState(false)
  const [username, setUsername] = useState('')
  const [telefono, setTelefono] = useState('')
  const [correo, setCorreo] = useState('')
  const [estado, setEstado] = useState('')
  const [ciudad, setCiudad] = useState('')
  const [mensaje, setMensaje] = useState()
  const [ciudades2, setCiudades2] = useState([])
  const [validEmail, setValidEmail] = useState(null)
  const [contacts, setContacts] = useState([])
  const params = new URLSearchParams(window.location.search);
  const oValue = params.get('o');
  const selectEstadoRef = useRef(null);
  const selectStateRef = useRef(null);

  const sendContactInfo = async () => {
    setActive(true)
    if (username == '' || username == undefined) {
      toastErr("El campo nombre no puede ir vacío")
      setErrorT1(true)
      setActive(false)
      return;
    } else { setErrorT1(false) }
    if (telefono == '' || telefono == undefined) {
      toastErr("El campo teléfono no puede ir vacío")
      setErrorT2(true)
      setActive(false)
      return;
    } else { setErrorT2(false) }
    if (correo == '' || correo == undefined) {
      toastErr("El campo correo no puede ir vacío")
      setErrorT3(true)
      setActive(false)
      return;
    } else { setErrorT3(false) }
    if (!validEmail) {
      toastErr("El correo no es valido")
      setErrorT3(true)
      setActive(false)
      return;
    } else { setErrorT3(false) }
    if (estado == '' || estado == undefined) {
      toastErr("El campo estado no puede ir vacío")
      setErrorT4(true)
      setActive(false)
      return;
    } else { setErrorT4(false) }
    if (ciudad == '' || ciudad == undefined) {
      toastErr("El campo ciudad no puede ir vacío")
      setErrorT5(true)
      setActive(false)
      return;
    } else { setErrorT5(false) }
    if (mensaje == '' || mensaje == undefined) {
      toastErr("El campo mensaje no puede ir vacío")
      setErrorT6(true)
      setActive(false)
      return;
    } else { setErrorT6(false) }

    const info = {
      city: ciudad.name,
      email: correo,
      message: mensaje,
      name: username,
      phone: telefono,
      state: estado.name
    }

    try {
      Requests.postContact(info).then((res) => {
        if (res.code == 200) {
          toastSuccess("Tu mensaje ha sido enviado, gracias.")
          setUsername('')
          setTelefono('')
          setCorreo('')
          setEstado('')
          setCiudad('')
          setMensaje('')
          if (selectEstadoRef?.current) {
            selectEstadoRef.current.value = '';
          }
          if (selectStateRef?.current) {
            selectStateRef.current.value = '';
          }
          if(oValue === 'sostenibilidad'){
            getMsg();
          }
        }
        setActive(false)
      }).catch((err) => {
        setActive(false)
        console.log(err)
      })
    } catch (error) {
      console.log(error)
      setActive(false)
    }
  }

  const toastErr = (msg) => {
    toast.error(msg, {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }

  const toastSuccess = (msg) => {
    toast.success(msg, {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }

  const checkEmail = () => {
    if (!/^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i.test(correo)) {
      setErrorT3(true)
      setValidEmail(false)
    } else {
      setErrorT3(false)
      setValidEmail(true)
    }
  }

  useEffect(() => {
    window.scrollTo(0, 0)
    getContacts()
  }, []);

  const getContacts = () => {
    try {
      Requests.getContacts().then((res) => {
        setContacts(res)
      })
    } catch (error) {
      console.log(error)
    }
  }

  const handleChange = (event) => {
    setUsername(event.target.value)
  }

  const handleChangeSelect = (event) => {
    const parseado = JSON.parse(event.target.value);
    //console.log(parseado)
    setCiudades2(ciudades.filter(item => item.state_id == parseado.id))
    setEstado(parseado);
  }

  const handleChangeSelect2 = (event) => {
    const parseado = JSON.parse(event.target.value)
    setCiudad(parseado)
  }

  const getMsg = async () => {
    const response = await getMsgSostenibilidad();
    setMensaje(response?.default_email_message_sustainability);
  };

  useEffect(() => {
    if(oValue === 'sostenibilidad'){
      getMsg();
    }
  }, [oValue]);

  return (
    <div>
      <div className="w-full">
        <ToastContainer position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover />
        <div className="w-full inner-page-container p-4 contact-italic">
          <div className="container">
            <div className="row">
              <div className="col-sm-12">
                <h1>Contacto</h1>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-6 pb-12">
                <h6 className="mb-2">Deja tus datos y a la brevedad posible nos estaremos contactando contigo</h6>
                <form onsubmit="return false">
                  <input onError={() => { alert('vacio') }} type="text" required className="mt-3 form-control" style={{ padding: 10, borderColor: errorT1 ? 'red' : 'rgb(162, 185, 226)' }} placeholder="Nombre*" value={username} onChange={(e) => { setUsername(e.target.value) }} />
                  <input type="number" onInput={(e) => {
                    e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 10)
                  }} required maxLength={10} className="mt-3 no-arrow form-control" style={{ padding: 10, borderColor: errorT2 ? 'red' : 'rgb(162, 185, 226)' }} placeholder="Teléfono*" value={telefono} onChange={(e) => { setTelefono(e.target.value) }} />

                  <input required type="email" onBlur={() => { checkEmail() }} className="mt-3 form-control" style={{ borderColor: errorT3 ? 'red' : 'rgb(162, 185, 226)', padding: 10 }} placeholder="Correo electrónico*" value={correo} onChange={(e) => { setCorreo(e.target.value) }} />
                  <div className='row'>
                    <div className='col-md-6'>
                      <select 
                        required 
                        className="mt-3 form-select" style={{  borderColor: errorT4 ? 'red' : 'rgb(162, 185, 226)', padding: 10 }} onChange={handleChangeSelect} ref={selectEstadoRef}>
                        <option label="Estado*"></option>
                        {estados.map((item, index) => (
                          <option key={index} value={JSON.stringify(item)}>{item.name}</option>
                        ))}
                      </select>
                    </div>
                    <div className='col-md-6'>
                      <select required className="mt-3 form-select" style={{ borderColor: errorT5 ? 'red' : 'rgb(162, 185, 226)', padding: 10 }} onChange={handleChangeSelect2} ref={selectStateRef}>
                        <option label="Ciudad*"></option>
                        {ciudades2.map((item, index) => (
                          <option key={index} value={JSON.stringify(item)}>{item.name}</option>
                        ))}
                      </select>

                    </div>
                  </div>

                  <textarea 
                    required 
                    className="my-3 form-control" 
                    style={{ borderColor: errorT6 ? 'red' : 'rgb(162, 185, 226)', padding: 10 }} 
                    placeholder="Mensaje*" 
                    onChange={(e) => { setMensaje(e.target.value) }} 
                    value={mensaje} 
                    cols="40"
                    rows="5"
                    disabled={oValue === 'sostenibilidad'}
                  ></textarea>
                  <div className="items-center justify-center flex">
                    <button className="2 contact-btn-send" type="button" onClick={() => { sendContactInfo() }} disabled={active}>ENVIAR</button>
                  </div>
                </form>
              </div>
              <div className="col-sm-5 offset-sm-1">
                <div className='mb-4'>
                <h5 className="" >Corporativo - Red Ambiental</h5>
                <p><IconButton size="large" edge="start" color="inherit" aria-label="menu"> <Location style={{ color: '#0070a5' }} /></IconButton> <p style={{ marginLeft: '40px', position: 'absolute', marginTop: '-40px' }}>
                Boulevard Díaz Ordaz # 130, Torre 4, Piso 16, Colonia Santa María, Monterrey, <br/> N.L. México, C.P. 64650<br/>52 81 81 74 74 74</p></p>
                <br />
                </div>
                {contacts.length > 0 && (
                  <>
                    {contacts.map((item, index) => (
                      <>
                        <h5 key={index} className="" >{item.name}</h5>
                        <p className=""><a className="taga-text-decorate" href={`tel:${item.phone_number}`}><IconButton size="large" edge="start" color="inherit" aria-label="menu"> <Phone style={{ color: '#0070a5' }} /></IconButton> {`Tel. ${item.phone_number}`}</a><br />
                          {
                            (item.email) &&
                            <a className="taga-text-decorate" href={`mailto:${item.email}`}><IconButton size="large" edge="start" color="inherit" aria-label="menu"> <Email style={{ color: '#0070a5' }} /></IconButton> {` Email: ${item.email}`}</a>
                          }
                        </p>
                        <br />
                      </>
                    ))}
                  </>
                )}
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  )
}

export default Contacto